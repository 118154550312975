
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { IBaseComponent, IBaseComponentAndStyle } from '@/app/Survey/model/Form';
import { IAnswer, IComponent, IRadioAnswer } from '@/services/FormStructure';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import RadioButtonComponent from '../RadioButton/RadioButtonComponent.vue';
import LikeDislikeComponent from '../LikeDislike/LikeDislikeComponent.vue';
import DataUrl from '@/app/Survey/model/DataUrl';

@Component({ components: { WrapperFormQuestion, RadioButtonComponent, LikeDislikeComponent } })
export default class ParentGroupComponent extends Mixins(QuestionMixin) {
    @Prop({ default: null }) public readonly urlData!: DataUrl;

    constructor() {
        super();
        if (!this.currentComponent.answer) this.currentComponent.answer = {};
    }

    public twoColumns: boolean = true;
    public childrenAreAnswered: boolean = false;

    public get isAllChildrenAnswered(): boolean {
        return this.childrenAreAnswered;
    }

    public get mappedChildrenComponents(): Record<string, string> {
        return {
            RadioButtonComponent: 'RadioButtonComponent',
            DynamicLikeDislikeComponent: 'LikeDislikeComponent',
        };
    }

    public get isCurrentComponentLDD(): boolean {
        return this.currentComponent.settings.childrenType === 'DynamicLikeDislikeComponent';
    }

    public get isEnvironmentSandbox(): boolean {
        return this.urlData?.environment === 'sandbox';
    }

    public handleUpdatedComponent(objAnswer: IComponent): void {
        const answer = JSON.parse(JSON.stringify(objAnswer));
        const component: IBaseComponentAndStyle = JSON.parse(JSON.stringify(this.currentComponent));

        component.children.forEach((child: IBaseComponent) => {
            if (child.id === answer.id) {
                child.answer = { ...child.answer, ...answer };
                answer.parentId = child.parentId;
            }
        });

        component.answer = {};

        this.setParentGroupCurrentAnswer(component);
        this.$emit('updatedComponent', answer);
    }

    private setParentGroupCurrentAnswer(component: IBaseComponentAndStyle): void {
        this.childrenAreAnswered = component.children.every(child => !!child?.answer && !this.isObjectAnswerEmpty(child.answer));
    }

    private isObjectAnswerEmpty(objectAnswer: IAnswer | IAnswer[] | IRadioAnswer): boolean {
        return Object.keys(objectAnswer).length === 0;
    }
}
