
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ITheme } from '@/app/Survey/model/Theme';
import { ColumnStyle } from '@/app/Survey/model/Form';

@Component
export default class ColumnHeader extends Vue {
    @Prop() public componentContent!: { title: string; description: string };
    @Prop() public columnStyle!: ColumnStyle;
    @Prop() public componentSettings: { title: boolean; description: boolean };
    @Prop() public theme!: ITheme;

    public get textAlignStyle() {
        return {
            textAlign: this.columnStyle?.alignment,
        };
    }
}
