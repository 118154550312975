
import { Component, Mixins } from 'vue-property-decorator';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';

@Component({
    components: {
        WrapperFormQuestion,
    },
})
export default class FreeTextComponent extends Mixins(QuestionMixin) {
    public get questionColorStyle() {
        return {
            color: this.theme?.question?.color,
        };
    }
}
