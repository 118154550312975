
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Skeleton extends Vue {
    @Prop({ default: 1 }) private amount!: number;
    @Prop({ default: 20 }) private height!: number;
    @Prop({ default: 100 }) private width!: number;
    @Prop({ default: 'px' }) private unit!: string;

    public get setHeight(): string {
        return `${this.height}${this.unit}`;
    }

    public get setWidth(): string {
        return `${this.width}%`;
    }
}
