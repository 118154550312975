enum PrivacyPlacement {
    survey = 'survey',
    enrichment = 'enrichment',
}

enum DisplayType {
    emoticon = 'emoticon',
    number = 'number',
}

interface PrivacyLink {
    label: string;
    href: string;
    placement?: PrivacyPlacement;
}

interface JourneyEnrichAgreements {
    label?: string;
    name?: string;
    href?: string;
    version?: string;
    required?: boolean;
}

export default interface JourneyParameters {
    sendButtonText?: string;
    surveyTitle?: string;
    name?: string;
    logo?: string;
    color?: string;
    question?: string;
    displayType?: DisplayType;
    npsColor?: boolean;
    classesLabel?: string;
    commentLabel?: string;
    commentNegativeLabel?: string;
    classesNotRequired?: boolean;
    showClasses?: boolean;
    allowHtml?: boolean;
    showFixedClasses?: boolean;
    autoSendTimeout?: number;
    timeoutToThanksScreen?: number;
    showComment?: boolean;
    showAnonymous?: boolean;
    showAcceptanceContactIfDetractor?: boolean;
    showEnrichment?: boolean;
    showNameEnrichment?: boolean;
    showEmailEnrichment?: boolean;
    showAddressEnrichment?: boolean;
    showPhoneEnrichment?: boolean;
    showGenderEnrichment?: boolean;
    showBirthEnrichment?: boolean;
    showAdditionalEnrichment?: boolean;
    showMoreThan6Classes?: boolean;
    showInHouseAds?: 'remunerada' | 'solidaria' | 'both' | 'none' | 'remunerada_sobdemanda';
    customAcknowledgmentHTML?: string;
    showDocumentEnrichment?: boolean;
    showEntityDocumentEnrichment?: boolean;
    showSendButton?: boolean;
    showThanksScreen?: boolean;
    saveInitialRating?: boolean;
    successTitle?: string;
    successMessage?: string;
    thanksMessage?: string;
    dateTimeMessage?: string;
    template?: string;
    customStyle?: string;
    hideCredits?: boolean;
    privacyLinks?: PrivacyLink[];
    enrichAgreements?: JourneyEnrichAgreements[];
    enrichDisclaimer?: string;
    preEnrich?: boolean;
    reEnrich?: boolean;
    enrichmentTitle?: string;
    completedUrlRedirection?: string;
    lockScroll?: boolean;
    enableQRCodeQuarantine?: boolean;
    enableCookieQuarantine?: boolean;
    enableInformChannelsIfDetractor?: boolean;
    customAcceptanceContactText?: string;
    methodTerms?: Record<string, string[]>;
    fingerprintApiKey?: string | null;
    fingerprintQuarantineRange?: string;
}

export class JourneyParametersDefault implements JourneyParameters {
    public saveInitialRating = true;
    public allowHtml = false;
    public classesLabel = '';
    public classesNotRequired = false;
    public active = false;
    public color = '';
    public commentLabel = '';
    public commentNegativeLabel = '';
    public dateTimeMessage = '';
    public displayType = null;
    public inputType = null;
    public language = '';
    public name = '';
    public npsColor = null;
    public question = '';
    public showAnonymous = true;
    public showAcceptanceContactIfDetractor = false;
    public showClasses = true;
    public showMoreThan6Classes = false;
    public showFixedClasses = false;
    public showComment = true;
    public reEnrich = false;
    public preEnrich = false;
    public showNameEnrichment = false;
    public showEmailEnrichment = false;
    public showGenderEnrichment = false;
    public showAddressEnrichment = false;
    public showPhoneEnrichment = false;
    public showDocumentEnrichment = false;
    public showEntityDocumentEnrichment = false;
    public showBirthEnrichment = false;
    public hideCredits = null;
    public customAcknowledgmentHTML = '';
    public autoSendTimeout = 5000;
    public showSendButton = true;
    public successMessage = '';
    public successTitle = '';
    public thanksMessage = '';
    public template = '';
    public customStyle = null;
    public privacyLinks = null;
    public enrichAgreements = [];
    public enrichDisclaimer = null;
    public enrichmentTitle = null;
    public completedUrlRedirection = '';
    public lockScroll = null;
    public showThanksScreen = true;
    public enableCookieQuarantine = false;
    public acceptanceStrategy = 'customer';
    public enableInformChannelsIfDetractor = false;
    public methodTerms = { ces: [] };
    public enableColletingFingerprint = false;
}
