import Form from '@/app/Survey/Form.vue';
import PassedPage from '@/app/Survey/PassedPage.vue';
import FormUnavailable from '@/app/Survey/FormUnavailable.vue';
import Survey from '@/app/Survey/Survey.vue';
import DefaultEndingPage from '@/app/Survey/DefaultEndingPage.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:apiKey/:formId',
            component: Survey,
            children: [
                { path: '', name: 'page', component: Form }, //
                { path: 'thanks', name: 'defaultEndingPage', component: DefaultEndingPage },
                { path: 'alreadyAnswred', name: 'alreadyAnswered', component: PassedPage },
                { path: 'formUnavailable', name: 'formUnavailable', component: FormUnavailable },
            ],
        },
    ],
});

export default router;
