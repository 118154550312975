
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { IBaseComponentAndStyle } from '@/app/Survey/model/Form';
import { IAnswer } from '@/services/FormStructure';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BFormGroup, BFormRadio } from 'bootstrap-vue';

@Component({ components: { WrapperFormQuestion, BFormGroup, BFormRadio } })
export default class RadioButtonComponent extends Mixins(QuestionMixin) {
    @Prop({ default: false }) public twoColumns!: boolean;
    @Prop({ default: false }) public readonly isParentGroup!: boolean;

    constructor() {
        super();
        if (!this.currentComponent.answer) this.currentComponent.answer = {};
    }

    get radioOptions() {
        return this.componentSettings.radioSettings.radioOptions;
    }

    set answer(value: number) {}
    get answer(): number {
        return this.singleAnswer?.answer ?? null;
    }

    get questionStructure(): string {
        return this.columnAmount === 1 ? 'flex-column flex-lg-row justify-content-between' : 'flex-column';
    }

    get radioButtonWrapperWidth(): string {
        return this.columnAmount === 1 ? 'col-auto col-lg-6' : 'col-auto';
    }

    get subtitleBackground(): string {
        return this.columnAmount === 2 ? 'bg-light' : '';
    }

    get isGroupedRadioButton(): boolean {
        return this.isParentGroup;
    }

    get buttonTextColorFromTheme(): string {
        return !!this.theme?.question?.color ? this.theme?.question?.color : '';
    }

    get widthOfOptions(): string {
        return this.radioOptions.length > 3 ? 'min-width: 400px' : 'min-width: 250px';
    }

    public setRadioAnswer(answer: IAnswer): void {
        const component: IBaseComponentAndStyle = JSON.parse(JSON.stringify(this.currentComponent));
        if (!component.answer) component.answer = {};
        component.answer = {
            ...component.answer,
            ...answer,
        };
        this.$emit('updatedComponent', component);
    }

    public check(optionIndex: number): boolean {
        return this.answer === optionIndex;
    }

    public haveQuestionAndIsNotGroupedRadioButton(question: string): boolean {
        return !!question && !this.isGroupedRadioButton;
    }
}
