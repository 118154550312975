import { FormComponentsType, IBaseComponentAndStyle, IComponentContent, IComponentSettings, IComponentStyle } from '@/app/Survey/model/Form';
import { ITheme } from '@/app/Survey/model/Theme';
import { IAnswer } from '@/services/FormStructure';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DataUrl from '../model/DataUrl';

@Component
export default class QuestionMixin extends Vue {
    @Prop() public componentStyle: IComponentStyle;
    @Prop() public componentSettings: IComponentSettings;
    @Prop() public componentContent: IComponentContent;
    @Prop() public currentComponent: IBaseComponentAndStyle;
    @Prop() public theme!: ITheme;
    @Prop() public columnAmount!: number;
    @Prop() public mainColor!: string;
    @Prop({ default: null }) public urlData!: DataUrl;
    @Prop({ default: false }) public readonly shouldShowOnlyRequiredLabels!: boolean;
    @Prop({ default: false }) public readonly haveAtLeastOneRequiredQuestion!: boolean;
    @Prop({ default: false }) public readonly isMissingRequiredQuestion!: boolean;
    @Prop({ default: false }) public readonly isRequired!: boolean;

    public textAreaWidth = '0%';

    mounted(): void {
        this.columnResize();
    }

    created(): void {
        window.addEventListener('resize', this.columnResize);
        if (this.isMainQuestion() && ['NumericButtonComponent', 'EmojiComponent'].includes(this.currentComponent.type)) {
            this.setScoreForMainQuestion();
        }
    }

    destroyed(): void {
        window.removeEventListener('resize', this.columnResize);
    }

    public get getComponentStyle(): { [key: string]: string } | IComponentStyle {
        if (!this.componentStyle.size) {
            return this.componentStyle;
        }
        return {
            ...this.componentStyle,
            height: this.componentStyle.size.height,
            width: this.textAreaWidth,
        };
    }

    public get singleAnswer(): IAnswer {
        if (!this.isMultiple && !Array.isArray(this.currentComponent.answer)) {
            return this.currentComponent.answer;
        }
        return null;
    }

    public get isMultiple(): boolean {
        return this.componentSettings?.selectionStrategy === 'multiple' || false;
    }

    public get buttonTextStyle() {
        if (this.theme?.buttonText) {
            return {
                color: this.cleanOverwrite(this.theme.buttonText.color),
                fontFamily: this.theme.buttonText.fontFamily,
                fontSize: this.theme.buttonText.fontSize,
                fontWeight: this.theme.buttonText.fontWeight,
            };
        }
        return {};
    }

    public get currentAnswer(): boolean {
        return (
            (Array.isArray(this.currentComponent.answer) && this.currentComponent.answer.some(an => an.answer)) ||
            !!(this.currentComponent?.answer as IAnswer)?.answer ||
            !!(this.currentComponent?.answer as IAnswer)?.text
        );
    }

    private getMultipleAnswers(answer: IAnswer): IBaseComponentAndStyle {
        const entity = JSON.parse(JSON.stringify(this.currentComponent));
        if (entity?.answer === undefined) {
            entity.answer = [];
        }

        entity.answer = entity.answer.filter((item: IAnswer) => item?.answer != null);
        if (Array.isArray(entity.answer)) {
            const alreadyHadThisAnswer: IAnswer[] = entity?.answer?.filter((item: IAnswer) => item.answer === answer.answer);
            if (alreadyHadThisAnswer.length) {
                entity.answer = entity?.answer?.filter((item: IAnswer) => item.answer !== alreadyHadThisAnswer[0].answer);
            } else {
                entity.answer.push({ ...answer });
            }
        }
        return entity;
    }

    public columnResize(): void {
        const column = document.querySelector('.column') ? document.querySelector('.column').clientWidth - 32 : null;
        const columnWidth = this.componentStyle && this.componentStyle.size ? Number(this.componentStyle.size.width.split('px')[0]) : null;
        if (this.componentStyle && column > columnWidth) {
            this.textAreaWidth = `${(columnWidth * 100) / column}%`;
        } else {
            this.textAreaWidth = '100%';
        }
    }

    public setAnswer(answer: IAnswer): void {
        if (this.isMultiple) {
            this.$emit('updatedComponent', this.getMultipleAnswers(answer));
        } else {
            const entity: IBaseComponentAndStyle = JSON.parse(JSON.stringify(this.currentComponent));
            entity.answer = { ...entity.answer, ...answer };
            if (this.currentComponent.type === FormComponentsType.SimpleTextComponent) {
                entity.answer = { answer: null, text: answer.text };
            }
            this.$emit('updatedComponent', entity);
        }
    }

    public get componentAlignment(): { [key: string]: string } {
        const objAlign = {
            left: 'flex-start',
            right: 'flex-end',
            center: 'center',
        };

        return {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': objAlign[this.componentStyle?.alignment] ?? 'flex-start',
            'text-align': this.componentStyle?.alignment ?? 'left',
        };
    }

    public cleanOverwrite(property: string): string {
        property = property.replace(' !important', '');
        property = property.replace('!important', '');
        return property;
    }

    public isMainQuestion(): boolean {
        return this.currentComponent?.settings?.isMainQuestion ?? false;
    }

    private getScore(): number | null {
        const isNumericButton = 'NumericButtonComponent' === this.currentComponent.type;
        const emojiAllowedScoreList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const allowedScoresList = isNumericButton ? this.getAllowedScoresNumericButton() : emojiAllowedScoreList;
        return allowedScoresList.includes(this.urlData.score) ? this.urlData.score : null;
    }

    /**
     * Get range of allowed based on methodology
     * @returns IF methodology equal "CES" returns:[1,2,3,4,5]
     * @returns IF methodology equal "NPS" or "CSAT" returns:[0,1,2,3,4,5,6,7,8,9,10]
     * @returns IF methodology equal "NPS" or "CSAT" returns:[0,1,2,3,4,5,6,7,8,9,10]
     */
    private getAllowedScoresNumericButton(): number[] {
        const { startAndFinish } = { ...this.componentSettings.numericButtonConfiguration };
        const length = startAndFinish.finish - startAndFinish.start + 1;
        return Array.from({ length }, (_, i) => i + startAndFinish.start);
    }

    private setScoreForMainQuestion(): void {
        const objAnswerByScore: IAnswer = {
            answer: this.getScore(),
            formQuestionId: this.currentComponent.id,
        };

        if (objAnswerByScore.answer != null) {
            this.setAnswer(objAnswerByScore);
        }
    }
}
