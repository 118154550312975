
import { Component, Vue, Prop } from 'vue-property-decorator';
import IAlertConfig from '@/dtos/IAlertConfig';

@Component({ components: {} })
export default class DefaultAlert extends Vue {
    @Prop() public value: boolean = false;
    @Prop() private options?: IAlertConfig;

    get optionsMutated(): IAlertConfig {
        return this.options!;
    }

    get show(): boolean {
        return this.value;
    }

    set show(value) {
        this.$emit('input', value);
    }
}
