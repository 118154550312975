declare let window: any;
window.Vue = Vue;

import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import i18n from '@/services/Setupi18n';

import router from './router';

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

Vue.use(BootstrapVue);
Vue.use(VueI18n);

import ServiceInitializer from '@/services/ServiceInitializer';
import BaseService from './services/BaseService';
import LocationService from './services/LocationService';

Vue.config.productionTip = false;

main();
function main() {
    const locationService = new LocationService(location);
    const services: Record<string, BaseService> = new ServiceInitializer().init(locationService.instanceToken);

    Vue.component('treeselect', Treeselect);

    new Vue({
        render: h => h(App),
        router,
        i18n,
        data: {
            instanceName: null,
        },
        provide: {
            ...services,
        },
    }).$mount('#app');
}
