import { IAnswer } from '@/services/FormStructure';

export const setAnswer = (transactionId: number | string, answer: IAnswer[], formQuestionId: number, formId: number): void => {
    let answers = null;

    // Doesn't have any cached answer yet? Will create here;
    if (!getAnswers(transactionId, formId)) {
        setAnswers(transactionId, [answer], formId);
        return;
    }

    answers = JSON.parse(JSON.stringify(getAnswers(transactionId, formId)));
    const answersList = new Set(answer.map(item => item.formQuestionId));
    const alreadyAnswerThatQuestion = answers.some((el: IAnswer) => answersList.has(el[0]?.formQuestionId));

    // Remove all cached answers when the question was clear by the user
    const haveAnyInvalidAnswer = answer.every((item: IAnswer): boolean => item.answer === null && item?.text === null);
    if (haveAnyInvalidAnswer) {
        answers = answers.filter((item: IAnswer[]) => {
            // Create a list with only the formQuestion Id and make sure doen't have duplicate id
            const questionAnswered = new Set(item.map((el: IAnswer) => el.formQuestionId));
            // Return only question that have valid answer
            return !questionAnswered.has(formQuestionId);
        });
        setAnswers(transactionId, answers, formId);
        return;
    }

    // If already Answer the question will update the answer
    if (alreadyAnswerThatQuestion) {
        answers = updateAnswerValue(transactionId, answer, formId);
        setAnswers(transactionId, answers, formId);
        return;
    }

    // Handle with multiple answers for ButtonSelectComponent and Autocomplete
    answers.push(answer);
    answers.forEach((item: IAnswer[], index: number) => {
        const multipleAnswerQuestionHasChange = answers.some((el: IAnswer) => answer[0]?.formQuestionId === el[0]?.formQuestionId && item[0]?.formQuestionId === el[0]?.formQuestionId);
        if (multipleAnswerQuestionHasChange) {
            const answerStorage: IAnswer[] = JSON.parse(JSON.stringify(answer));
            if (Object.keys(answerStorage[0]).includes('answer')) {
                answers[index] = answer;
            } else {
                item.forEach((answerItem: IAnswer, idx: number) => {
                    if (answerItem.formQuestionId === answerStorage[0].formQuestionId && answerItem.answer === undefined) {
                        item.splice(idx, 1);
                    }
                });
            }
        }
    });
    setAnswers(transactionId, answers, formId);
};

function updateAnswerValue(transactionId: number | string, answer: IAnswer[], formId: number): IAnswer[] {
    const localAnswers = JSON.parse(JSON.stringify(getAnswers(transactionId, formId)));
    const answers = localAnswers.map((el: IAnswer) => {
        if (el[0]?.formQuestionId === answer[0]?.formQuestionId) return answer;
        return el;
    });
    return answers as IAnswer[];
}

export const setAnswers = (transactionId: number | string, answers: IAnswer[][], formId: number): void => {
    localStorage.setItem(`answers-tid${transactionId}-fid${formId}`, JSON.stringify(answers));
};

export const getAnswers = (transactionId: number | string, formId: number): IAnswer[][] | undefined => {
    const getDataByTransactionId: IAnswer[][] | undefined = JSON.parse(localStorage.getItem(`answers-tid${transactionId}`));
    if (getDataByTransactionId) return getDataByTransactionId;
    return JSON.parse(localStorage.getItem(`answers-tid${transactionId}-fid${formId}`));
};

export const setPage = (transactionId: number | string, page: number, formId: number): void => {
    localStorage.setItem(`page-tid${transactionId}-fid${formId}`, String(page));
};

export const getPage = (transactionId: number | string, formId: number): number => {
    return Number(localStorage.getItem(`page-tid${transactionId}-fid${formId}`));
};

export const removeData = (transactionId: number | string, formId: number): void => {
    localStorage.removeItem(`answers-tid${transactionId}-fid${formId}`);
    localStorage.removeItem(`page-tid${transactionId}-fid${formId}`);
    localStorage.removeItem(`answers-tid${transactionId}`);
    localStorage.removeItem(`page-tid${transactionId}`);
};
