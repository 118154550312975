import ConditionalQuestionState from '@/app/Survey/model/conditionals/ConditionalQuestionState';
import Element from '@/app/Survey/model/conditionals/Element';
import Action from '@/app/Survey/model/conditionals/enum/Action';
import LogicalOperator from '@/app/Survey/model/conditionals/enum/LogicalOperator';

import ExecuteCondition from '@/services/ExecuteCondition';
import { AnswerConditionalType } from '@/services/FormStructure';
import ConditionalDto, { ConditionQuestionStateDto, ElementDto } from './ConditionalDto';
import ElementType from './enum/ElementType';
import Page from './Page';

export default class Conditional {
    public id: number;
    public formId: number;
    public trigger: Element;
    public logicalOperator: LogicalOperator;
    public conditions: ConditionalQuestionState[];
    public action: Action;
    public targets: Element[];

    constructor(conditional: ConditionalDto) {
        this.id = conditional.id;
        this.formId = conditional.formId;
        this.trigger = this.getElement(conditional.trigger);
        this.logicalOperator = conditional.logicalOperator;
        this.conditions = this.getConditions(conditional.conditions);
        this.action = conditional.action;
        this.targets = this.getElements(conditional.targets);
    }

    private getElement({ id = null, type }): Element {
        const elements = {
            [ElementType.PAGE]: Page,
            [ElementType.ELEMENT]: Element,
        };
        return new elements[type](id);
    }

    private getElements(targets: ElementDto[]) {
        return targets.map(target => this.getElement(target));
    }

    private getConditions(conditions: ConditionQuestionStateDto[]) {
        return conditions.map(
            condition =>
                new ConditionalQuestionState(
                    condition.denying, //
                    condition.questionId,
                    condition.expect,
                    condition.conditionOperator,
                    condition.questionElement,
                ),
        );
    }

    public getId(): number {
        return this.id;
    }

    public shouldShowItsSelf(): boolean {
        const conditionsResult = [];
        const conditions = this.getConditionsIgnoringShowTrigger();

        conditions.forEach((cond: ConditionalQuestionState) => {
            // trigger always case
            const isAlwaysConditionalTrigger = cond.denying === null;
            let answer = null;
            let unansweredElements = true;
            if (Array.isArray(cond.questionElement?.answer)) {
                unansweredElements = cond.questionElement?.answer?.length === 0;
                answer = cond.questionElement?.answer;
            } else {
                answer = cond.questionElement?.answer?.answer ?? cond.questionElement?.answer?.text;
                unansweredElements = answer === undefined || answer === null;
            }

            if (isAlwaysConditionalTrigger && unansweredElements && this.action === Action.HIDE) {
                conditionsResult.push(true);
            } else if (unansweredElements && this.action === Action.SHOW) {
                conditionsResult.push(false);
            } else {
                conditionsResult.push(this.prepareExecuteCondition(cond, answer));
            }
        });

        const conditionLogicalOperator = {
            [LogicalOperator.OR]: this.someConditionShouldBeTrue,
            [LogicalOperator.AND]: this.everyConditionShouldBeTrue,
        };

        const isValid = conditionLogicalOperator[this.logicalOperator](conditionsResult);
        return this.action === Action.SHOW ? this.shouldShow(isValid) : this.shouldHide(isValid);
    }

    public getConditionsIgnoringShowTrigger(): ConditionalQuestionState[] {
        const conditionsToIngore = [];
        this.conditions.forEach((cond: ConditionalQuestionState, index: number) => {
            const isAlwaysConditionalTrigger = cond.denying === null;
            if (isAlwaysConditionalTrigger && this.action === Action.SHOW) {
                conditionsToIngore.push(index);
            }
        });
        return this.conditions.filter((_, index: number) => !conditionsToIngore.includes(index));
    }

    public prepareExecuteCondition(cond: ConditionalQuestionState, answer: AnswerConditionalType): boolean {
        const executeCondition = new ExecuteCondition(cond, answer);
        return executeCondition[cond.conditionOperator]();
    }

    private everyConditionShouldBeTrue(conditionsResult: boolean[]): boolean {
        return conditionsResult.every((item: boolean) => item);
    }

    private someConditionShouldBeTrue(conditionsResult: boolean[]): boolean {
        return conditionsResult.some((item: boolean) => item);
    }

    public shouldHide(isValid: boolean): boolean {
        return !isValid;
    }

    public shouldShow(isValid: boolean): boolean {
        return isValid;
    }
}
