
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component({ components: { WrapperFormQuestion } })
export default class NumbersComponent extends Mixins(QuestionMixin) {
    public value: number = null;

    public created(): void {
        window.addEventListener('beforeunload', this.done);
    }

    public mounted() {
        this.value = this.singleAnswer?.answer;
    }

    public done(): void {
        if (Number.isFinite(this.value)) {
            this.setAnswer({ answer: this.value });
        }
    }

    public filterNumbers($event: KeyboardEvent): void {
        if (!/\d/.test($event.key)) $event.preventDefault();
        this.done();
    }
}
