import { Component, Vue } from 'vue-property-decorator';
import {
    AutoCompleteComponent,
    ButtonSelectComponent,
    EmojiComponent,
    FreeTextComponent,
    ImageComponent,
    LikeDislikeComponent,
    NumbersComponent,
    NumericButtonComponent,
    ParentGroupComponent,
    RadioButtonComponent,
    SimpleTextComponent,
} from './FormComponents/ComponentList';

@Component({
    components: {
        ImageComponent,
        LikeDislikeComponent,
        NumericButtonComponent,
        EmojiComponent,
        FreeTextComponent,
        SimpleTextComponent,
        NumbersComponent,
        ButtonSelectComponent,
        RadioButtonComponent,
        ParentGroupComponent,
        AutoCompleteComponent,
    },
})
export default class FormRegisterComponent extends Vue {}
