import { IAnswer } from '@/services/FormStructure';
import Conditional from './conditionals/Conditional';

export interface IComponentContent {
    title: string;
    description: string;
    value?: string;
    options?: string[];
}

export interface IComponentSettings {
    description?: boolean;
    isShuffled?: boolean;
    lockLastOption?: boolean;
    isMainQuestion?: boolean;
    isRequired?: boolean;
    numericButtonConfiguration?: {
        startAndFinish: {
            start: number;
            finish: number;
        };
        methodology: string;
    };
    imageData?: {
        image: string;
        imageName: string;
        width?: number;
    };
    methodology?: string;
    placeholder?: string;
    numberOfOptions?: number;
    minCharacter?: number;
    radioSettings?: {
        questionsText: [
            {
                question: string;
            },
        ];
        radioOptions: [
            {
                text: string;
                value: number;
            },
        ];
    };
    selectionStrategy?: 'single' | 'multiple';
    childrenType?: string;
}

export interface IComponentStyle extends ColumnStyle {
    size?: {
        width: string;
        height: string;
    };
}

export interface ColumnStyle {
    alignment?: 'left' | 'center' | 'right';
}

export interface IBaseComponent {
    content: IComponentContent;
    id: number;
    ui_id?: number;
    type: string;
    isQuestion: boolean;
    answer?: IAnswer | IAnswer[];
    parentId?: number;
}

export interface IBaseComponentAndStyle extends IBaseComponent {
    settings: IComponentSettings;
    style: IComponentStyle;
    conditionals?: Conditional[];
    children: IBaseComponent[];
}

export enum FormComponentsType {
    ImageComponent = 'ImageComponent',
    LikeDislikeComponent = 'LikeDislikeComponent',
    NumericButtonComponent = 'NumericButtonComponent',
    EmojiComponent = 'EmojiComponent',
    FreeTextComponent = 'FreeTextComponent',
    SimpleTextComponent = 'SimpleTextComponent',
    NumbersComponent = 'NumbersComponent',
    ButtonSelectComponent = 'ButtonSelectComponent',
    RadioButtonComponent = 'RadioButtonComponent',
    ParentGroupComponent = 'ParentGroupComponent',
}
