import ConditionalQuestionState from '@/app/Survey/model/conditionals/ConditionalQuestionState';
import { AnswerConditionalType } from './FormStructure';

export default class ExecuteCondition {
    private readonly conditionQuestionState: ConditionalQuestionState;
    private answer: AnswerConditionalType;

    constructor(condition: ConditionalQuestionState, answer: AnswerConditionalType) {
        this.conditionQuestionState = condition || null;
        this.setAnswer(answer);
    }

    public setAnswer(answer: AnswerConditionalType): void {
        if (Array.isArray(answer)) {
            this.answer = answer;
        } else {
            this.answer = typeof answer === 'object' ? answer?.answer : answer;
        }

        if (typeof this.answer === 'string') {
            this.answer = this.answer.toLowerCase();
        }
    }

    public getExpectValue(): AnswerConditionalType {
        let { expect } = this.conditionQuestionState;
        if (typeof expect === 'string') {
            expect = expect.toLowerCase();
        }
        return expect;
    }

    // Equal
    public EQ(): boolean {
        try {
            const { denying } = this.conditionQuestionState;
            const expect = this.getExpectValue();
            if (Array.isArray(this.answer)) {
                const answerList = this.answer.map(item => item.answer?.toString() || '');
                const isEqual = answerList.includes(expect as string);
                return denying ? !isEqual : isEqual;
            }
            return denying ? !(this.answer == expect) : this.answer == expect;
        } catch (e) {
            console.error('[EQ]Error to execute form conditions', e);
            return true;
        }
    }

    // Not equal
    public NE(): boolean {
        try {
            const { denying } = this.conditionQuestionState;
            const expect = this.getExpectValue();
            if (Array.isArray(this.answer)) {
                const answerList = this.answer.map(item => item.answer?.toString() || '');
                const isNotEqual = answerList.includes(expect as string);
                return denying ? isNotEqual : !isNotEqual;
            }

            return denying ? !(this.answer != expect) : this.answer != expect;
        } catch (e) {
            console.error('[NE]Error to execute form conditions', e);
            return true;
        }
    }

    // Greater or Equal
    public GE(): boolean {
        try {
            const { denying } = this.conditionQuestionState;
            const expect = this.getExpectValue();
            if (Array.isArray(this.answer)) {
                const answerList = this.answer.map(item => item.answer?.toString() || '');
                const isGreaterOrEqual = answerList.some(item => Number(item) >= Number(expect));
                return denying ? !isGreaterOrEqual : isGreaterOrEqual;
            }
            return denying ? !(this.answer >= expect) : this.answer >= expect;
        } catch (e) {
            console.error('[GE]Error to execute form conditions', e);
            return true;
        }
    }

    // Greater than
    public GT(): boolean {
        try {
            const { denying } = this.conditionQuestionState;
            const expect = this.getExpectValue();
            if (Array.isArray(this.answer)) {
                const answerList = this.answer.map(item => item.answer?.toString() || '');
                const isGreater = answerList.some(item => Number(item) > Number(expect));
                return denying ? !isGreater : isGreater;
            }
            return denying ? !(this.answer > expect) : this.answer > expect;
        } catch (e) {
            console.error('[GT]Error to execute form conditions', e);
            return true;
        }
    }

    // Lower or Equal
    public LE(): boolean {
        try {
            const { denying } = this.conditionQuestionState;
            const expect = this.getExpectValue();
            if (Array.isArray(this.answer)) {
                const answerList = this.answer.map(item => item.answer?.toString() || '');
                const isLowerOrEqual = answerList.some(item => Number(item) <= Number(expect));
                return denying ? !isLowerOrEqual : isLowerOrEqual;
            }
            return denying ? !(this.answer <= expect) : this.answer <= expect;
        } catch (e) {
            console.error('[LE]Error to execute form conditions', e);
            return true;
        }
    }

    // Lower than
    public LT(): boolean {
        try {
            const { denying } = this.conditionQuestionState;
            const expect = this.getExpectValue();
            if (Array.isArray(this.answer)) {
                const answerList = this.answer.map(item => item.answer?.toString() || '');
                const isLower = answerList.some(item => Number(item) < Number(expect));
                return denying ? !isLower : isLower;
            }
            return denying ? !(this.answer < expect) : this.answer < expect;
        } catch (e) {
            console.error('[LT]Error to execute form conditions', e);
            return true;
        }
    }
}
