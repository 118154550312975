
import { Component, Mixins } from 'vue-property-decorator';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import { BButton } from 'bootstrap-vue';
import ComponentOptions from '@/app/Survey/model/ComponentOptions';

@Component({
    components: {
        WrapperFormQuestion,
        BButton,
    },
})
export default class ButtonSelectComponent extends Mixins(QuestionMixin) {
    public options: ComponentOptions[] = [];

    public get answer(): number | number[] {
        if (this.isMultiple) {
            if (Array.isArray(this.currentComponent.answer) && this.currentComponent.answer) {
                return this.currentComponent.answer.map(item => item.answer);
            }
            return [];
        } else {
            if (this.currentComponent.answer && !Array.isArray(this.currentComponent.answer)) {
                return this.singleAnswer.answer;
            }
            return null;
        }
    }

    public created(): void {
        this.getOptions();
    }

    public getOptions(): void {
        const optionsWithIds: ComponentOptions[] = this.componentContent?.options.map((option, index) => ({
            id: index + 1,
            text: option,
        }));

        this.options = this.componentSettings.isShuffled ? this.shuffleOptions(optionsWithIds) : optionsWithIds;
    }

    private shuffleOptions(options: ComponentOptions[]): ComponentOptions[] {
        let fixedOption: ComponentOptions = null;

        if (this.componentSettings?.lockLastOption) {
            fixedOption = options.pop();
        }

        // Implementation of shuffling algorithm (Fisher-Yates)
        for (let currentIndex = options.length - 1; currentIndex > 0; currentIndex--) {
            const randomIndex = Math.floor(Math.random() * (currentIndex + 1));

            // Swap elements at positions `currentIndex` and `randomIndex` to shuffle
            [options[currentIndex], options[randomIndex]] = [options[randomIndex], options[currentIndex]];
        }

        if (fixedOption) options.push(fixedOption);

        return options;
    }

    public isPressed(optionId: number): boolean {
        return Array.isArray(this.answer) && this.answer.includes(optionId);
    }
}
