import ConditionOperator from '@/app/Survey/model/conditionals/enum/ConditionOperator';
import { IComponent } from '@/services/FormStructure';

export default class ConditionsQuestionState {
    public denying: boolean;
    public questionId: number;
    public expect: string | number;
    public conditionOperator: ConditionOperator;
    public questionElement?: IComponent;

    constructor(denying: boolean, questionId: number, expect: string | number, conditionOperator: ConditionOperator, questionElement: IComponent) {
        this.denying = denying;
        this.questionId = questionId;
        this.expect = expect;
        this.conditionOperator = conditionOperator;
        this.questionElement = questionElement;
    }
}
