import { render, staticRenderFns } from "./ImageComponent.vue?vue&type=template&id=b79e1614&scoped=true"
import script from "./ImageComponent.vue?vue&type=script&lang=ts"
export * from "./ImageComponent.vue?vue&type=script&lang=ts"
import style0 from "./ImageComponent.vue?vue&type=style&index=0&id=b79e1614&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b79e1614",
  null
  
)

export default component.exports