export class PathParameters {
    instanceToken: string;
    formId: number;
}

export default class LocationService {
    private pathParameters: PathParameters;

    constructor(private readonly location: Location) {
        this.location = location;
        this.pathParameters = this.getPathParameters();
    }

    get instanceToken(): string {
        return this.pathParameters.instanceToken;
    }

    private getPathParameters(): PathParameters {
        try {
            const splittedParameters: string[] = this.location.pathname.split('/');
            const cleanParameters: string[] = splittedParameters?.filter(p => p) ?? [];

            return {
                instanceToken: cleanParameters[0],
                formId: Number(cleanParameters[1]),
            };
        } catch (err) {
            console.error('Error getting path parameters [instance token or form id].');
        }

        return null;
    }
}
