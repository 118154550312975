import en from './lang/English';
import es from './lang/Spanish';
import pt from './lang/Portuguese';

export const defaultLocale = getInitialLanguage();
export const languages = { pt, en, es };

export function getLanguages() {
    return { en, pt, es };
}

export function getInitialLanguage(langs: string[] = ['pt', 'en', 'es'], defaultLang = 'pt'): string {
    const lang = getDefaultLanguage();
    return langs.indexOf(lang) >= 0 ? lang : defaultLang;
}

function getDefaultLanguage(defaultLang = 'pt') {
    const lang: string = getQueryLanguage() || navigator.language || defaultLang;
    return lang.substr(0, 2).toLowerCase().replace('-', '_');
}

function getQueryLanguage() {
    const urlParameters = new URLSearchParams(window.location.search);
    return urlParameters.get('lang');
}
