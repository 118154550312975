import Element from '@/app/Survey/model/conditionals/Element';
import ElementType from '@/app/Survey/model/conditionals/enum/ElementType';

class Page extends Element {
    get type(): ElementType {
        return ElementType.PAGE;
    }
}

export default Page;
