
import Steps from '@/app/Survey/model/Steps';
import { ITheme } from '@/app/Survey/model/Theme';
import { BButton } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { BButton } })
export default class PageSteps extends Vue {
    @Prop() public currentPage!: number;
    @Prop() public pagesAmount!: number;
    @Prop() public theme!: ITheme;
    @Prop() public mainColor!: string;

    public Steps = Steps;
}
