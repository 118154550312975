import { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';

export default class BaseService {
    private setApiKey(apiKey: string): void {
        this.client.defaults.headers['x-solucx-api-key'] = apiKey;
    }

    constructor(protected client: AxiosInstance, instanceToken: string) {
        this.client.interceptors.request.use(
            config => {
                config.headers['x-solucx-referer'] = window.location.href;
                return config;
            },
            error => {
                return Promise.reject(error);
            },
        );

        this.setApiKey(instanceToken);
    }

    protected async _get<T>(url: string, params?: any): Promise<T> {
        const defaults = this.client.defaults.params;
        const { data: response } = await this.client.get<T>(url, { params: { ...defaults, ...params } });
        return response;
    }

    protected async _post<T>(url: string, data?: any, cancelToken?: CancelToken): Promise<T> {
        const { data: response } = await this.client.post<T>(url, data, { cancelToken: cancelToken });
        return response;
    }

    protected async _put<T>(url: string, data?: any): Promise<T> {
        const { data: response } = await this.client.put<T>(url, data);
        return response;
    }

    protected async _del<T>(url: string, bodyData?: any): Promise<T> {
        const requestConfig = { data: bodyData } as AxiosRequestConfig;
        const { data: response } = await this.client.delete(url, requestConfig);
        return response;
    }
}
