import { render, staticRenderFns } from "./FreeTextComponent.vue?vue&type=template&id=6375e4e2&scoped=true"
import script from "./FreeTextComponent.vue?vue&type=script&lang=ts"
export * from "./FreeTextComponent.vue?vue&type=script&lang=ts"
import style0 from "./FreeTextComponent.vue?vue&type=style&index=0&id=6375e4e2&prod&scoped=true&lang=css"
import style1 from "./FreeTextComponent.vue?vue&type=style&index=1&id=6375e4e2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6375e4e2",
  null
  
)

export default component.exports