
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { BButton } from 'bootstrap-vue';
import { Component, Mixins } from 'vue-property-decorator';

@Component({ components: { WrapperFormQuestion, BButton } })
export default class NumericButtonComponent extends Mixins(QuestionMixin) {
    public get startAndFinish() {
        const length = this.componentSettings.numericButtonConfiguration['startAndFinish']['finish'] - this.componentSettings.numericButtonConfiguration['startAndFinish']['start'] + 1;
        return Array.from({ length }, (_, i) => i + this.componentSettings.numericButtonConfiguration['startAndFinish']['start']);
    }

    get answer(): number {
        if (this.currentComponent.answer) {
            return this.singleAnswer.answer;
        }
        return null;
    }
}
