import { AxiosRequestConfig } from 'axios';
import { IQuestions } from './FormStructure';
import ServiceProxy from './ServiceProxy';

export interface InterceptedRequest {
    match: (requestConfig: AxiosRequestConfig) => boolean;
    response: (requestConfig: AxiosRequestConfig) => unknown;
}

type MockedQuestion = IQuestions & { isMock: boolean };

export default class FormServiceProxy extends ServiceProxy {
    public invokeFakeService() {
        this.blockRequests([
            {
                match: this.compareRequest('get', /forms\/availability\//),
                response: requestConfig => this.rejectResponse(requestConfig),
            },
            {
                match: this.compareRequest('post', /forms\/answers\//),
                response: requestConfig => this.rejectResponse(requestConfig),
            },
            {
                match: this.compareRequest('post', /forms\/complete\//),
                response: requestConfig => this.rejectResponse(requestConfig),
            },
            {
                match: this.compareRequest('delete', /forms\/answers\/partial/),
                response: requestConfig => this.rejectResponse(requestConfig),
            },
        ]);

        this.stubResponses([
            {
                match: this.compareRequest('get', /forms\/availability\//),
                response: requestConfig => this.replyFormCompleted(requestConfig),
            },
            {
                match: this.compareRequest('post', /forms\/answers\//),
                response: requestConfig => this.replyStubbedSaveAnswer(requestConfig),
            },
            {
                match: this.compareRequest('post', /forms\/complete\//),
                response: requestConfig => this.replyStubbedCompletedForm(requestConfig),
            },
            {
                match: this.compareRequest('delete', /forms\/answers\/partial/),
                response: requestConfig => this.replyStubbedDeletePartialAnswers(requestConfig),
            },
        ]);
    }

    private replyFormCompleted(requestConfig: AxiosRequestConfig) {
        return {
            data: { isRatingCompleted: false, isMock: true },
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {
                ...requestConfig,
                data: { isRatingCompleted: false, isMock: true },
            },
            isMock: true,
        };
    }

    private replyStubbedSaveAnswer(requestConfig: AxiosRequestConfig) {
        const { data } = requestConfig;
        const answers = this.generateMockedAnswers(data);
        return {
            data: answers,
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {
                ...requestConfig,
                data: answers,
            },
            isMock: true,
        };
    }

    private generateMockedAnswers(data: IQuestions): MockedQuestion[] {
        const uniqueId = Date.now();
        return data.answers.reduce((acc, answer) => {
            acc.push({
                answer: answer.answer,
                formQuestionId: answer.formQuestionId,
                attemptId: data.attemptId,
                transactionId: data.transactionId,
                createdAt: new Date(uniqueId).toISOString(),
                id: uniqueId,
                isMainQuestion: false,
                isRequired: false,
                text: answer.text,
                isMock: true,
                parentId: answer?.parentId,
            });
            return acc;
        }, []);
    }

    private replyStubbedCompletedForm(requestConfig: AxiosRequestConfig) {
        return {
            data: {
                isMock: true,
            },
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {
                ...requestConfig,
                data: {
                    isMock: true,
                },
            },
            isMock: true,
        };
    }

    private replyStubbedDeletePartialAnswers(requestConfig: AxiosRequestConfig) {
        return this.replyStubbedCompletedForm(requestConfig);
    }
}
