import { ITheme, IStructureBackground, IButton, ITitle, IQuestion, IDescription, IButtonText, IConfig } from '@/app/Survey/model/Theme';

enum WeightFont {
    Regular = 400,
    Bold = 600,
}
const opacityValues = [
    '00',
    '03',
    '05',
    '08',
    '0A',
    '0D',
    '0F',
    '12',
    '14',
    '17',
    '1A',
    '1C',
    '1F',
    '21',
    '24',
    '26',
    '29',
    '2B',
    '2E',
    '30',
    '33',
    '36',
    '38',
    '3B',
    '3D',
    '40',
    '42',
    '45',
    '47',
    '4A',
    '4D',
    '4F',
    '52',
    '54',
    '57',
    '59',
    '5C',
    '5E',
    '61',
    '63',
    '66',
    '69',
    '6B',
    '6E',
    '70',
    '73',
    '75',
    '78',
    '7A',
    '7D',
    '80',
    '82',
    '85',
    '87',
    '8A',
    '8C',
    '8F',
    '91',
    '94',
    '96',
    '99',
    '9C',
    '9E',
    'A1',
    'A3',
    'A6',
    'A8',
    'AB',
    'AD',
    'B0',
    'B3',
    'B5',
    'B8',
    'BA',
    'BD',
    'BF',
    'C2',
    'C4',
    'C7',
    'C9',
    'CC',
    'CF',
    'D1',
    'D4',
    'D6',
    'D9',
    'DB',
    'DE',
    'E0',
    'E3',
    'E6',
    'E8',
    'EB',
    'ED',
    'F0',
    'F2',
    'F5',
    'F7',
    'FA',
    'FC',
    'FF',
];

const stylesByStructureBackground = (styleConfig: IStructureBackground): { [key: string]: string } => {
    const res = {};
    for (const key in styleConfig) {
        res[key] = {
            backgroundColor: `${styleConfig[key].styles.backgroundColor + opacityValues[styleConfig[key].styles.opacity]} !important`,
            backgroundImage: `url(${styleConfig[key].styles.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };
    }
    return res;
};

const stylesByButtonConfig = (styleConfig: IButton): { [key: string]: string } => {
    let res = {};
    for (const key in styleConfig) {
        res = {
            ...res,
            ...styleConfig[key].styles,
        };
        if (styleConfig[key].styles.hasOwnProperty('backgroundColor')) {
            res = {
                ...res,
                backgroundColor: `${styleConfig[key].styles.backgroundColor} !important`,
            };
        }
    }
    return res;
};

const stylesByDefaultConfig = (styleConfig: ITitle | IQuestion | IDescription | IButtonText): { [key: string]: string } => {
    let res = {};
    for (const key in styleConfig) {
        res = {
            ...res,
            ...styleConfig[key].styles,
        };
        if (styleConfig[key].styles.hasOwnProperty('fontSize')) {
            res = {
                ...res,
                fontSize: `${styleConfig[key].styles.fontSize}px`,
            };
        }
        if (styleConfig[key].styles.hasOwnProperty('fontWeight')) {
            res = {
                ...res,
                fontWeight: `${WeightFont[styleConfig[key].styles.fontWeight]} !important`,
            };
        }
        if (styleConfig[key].styles.hasOwnProperty('fontFamily')) {
            res = {
                ...res,
                fontFamily: `${styleConfig[key].styles.fontFamily}, sans-serif`,
            };
        }
        if (styleConfig[key].styles.hasOwnProperty('backgroundColor')) {
            res = {
                ...res,
                backgroundColor: `${styleConfig[key].styles.backgroundColor + opacityValues[styleConfig[key].styles.opacity]} !important`,
            };
        }
        if (styleConfig[key].styles.hasOwnProperty('color')) {
            res = {
                ...res,
                color: `${styleConfig[key].styles.color + opacityValues[styleConfig[key].styles.opacity]} !important`,
            };
        }
    }
    return res;
};

export const themeByConfig = (config: IConfig | null): ITheme | Record<string, unknown> => {
    let res = {};
    if (config) {
        for (const key in config) {
            const themeStructure = {
                structureBackground: (styleConfig): void => {
                    res = {
                        ...res,
                        ...stylesByStructureBackground(styleConfig),
                    };
                },
                title: (styleConfig): void => {
                    res['title'] = {
                        ...stylesByDefaultConfig(styleConfig),
                    };
                },
                question: (styleConfig): void => {
                    res['question'] = {
                        ...stylesByDefaultConfig(styleConfig),
                    };
                },
                description: (styleConfig): void => {
                    res['description'] = {
                        ...stylesByDefaultConfig(styleConfig),
                    };
                },
                button: (styleConfig): void => {
                    res['button'] = {
                        ...stylesByButtonConfig(styleConfig),
                    };
                },
                buttonText: (styleConfig): void => {
                    res['buttonText'] = {
                        ...stylesByDefaultConfig(styleConfig),
                    };
                },
            };
            themeStructure[key](config[key]);
        }
    }
    return res;
};
