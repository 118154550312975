import BaseService from '@/services/BaseService';
import JourneyParameters, { JourneyParametersDefault } from './JourneyParameters';

export default class JourneyService extends BaseService {
    public async getJourneyParametersByTransationId(transactionId: number): Promise<JourneyParameters> {
        try {
            return await this._get<JourneyParameters>(`journeys/get-by-transaction/${transactionId}`);
        } catch (error) {
            console.error("It wasn't possible load journey parameters", error);
            return new JourneyParametersDefault();
        }
    }
}
