
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import DataUrl from '@/app/Survey/model/DataUrl';
import { LIMIT_AUTOCOMPLETE_DEFAULT } from '@/helpers/Constants';
import FormService from '@/services/FormService';
import { IAnswer, VueTreeselectOptions } from '@/services/FormStructure';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { debounceFn } from 'debounce-decorator-ts';
import { Component, Inject, Mixins } from 'vue-property-decorator';

@Component({
    components: {
        WrapperFormQuestion,
    },
})
export default class AutoCompleteComponent extends Mixins(QuestionMixin) {
    @Inject() public formService!: FormService;

    public isLoadingOptions = true;
    public defaultOptions = [];

    public set answer(value: number | number[]) {}
    public get answer(): number | number[] {
        const answer = this.currentComponent.answer;
        if (this.isMultiple) {
            if (Array.isArray(answer) && answer && answer[0]?.answer != null) {
                return answer.map(item => item.answer);
            }
            return [];
        } else {
            if (answer && !Array.isArray(answer)) {
                return [this.singleAnswer.answer];
            }
        }
    }

    public get options(): Array<VueTreeselectOptions> {
        let optionsList = [];
        if (Array.isArray(this.currentComponent.answer) && this.currentComponent.answer) {
            const answer = this.currentComponent?.answer || [];
            optionsList = answer.map(item => {
                return {
                    id: item.answer,
                    label: item.text,
                };
            });
        } else if (this.currentComponent.answer) {
            const answerObj = this.currentComponent.answer as IAnswer;
            optionsList = [
                {
                    id: answerObj.answer,
                    label: answerObj.text,
                },
            ];
        }
        return optionsList;
    }

    public get isMultiple(): boolean {
        return this.componentSettings?.selectionStrategy === 'multiple' ?? true;
    }

    public get placeholder(): string {
        return this.componentSettings?.placeholder || '';
    }

    public get minCharacter(): number {
        return this.componentSettings?.minCharacter || 3;
    }

    public get formId(): number {
        const urlData = this.urlData || new DataUrl(this.$route);
        return Number(urlData!.formId) || null;
    }

    public get questionId(): number {
        return this.currentComponent?.id || null;
    }

    public get limit(): number {
        return LIMIT_AUTOCOMPLETE_DEFAULT;
    }

    public async mounted(): Promise<void> {
        const autoCompleteList = await this.getAutoCompleteList();
        const optionsIdList = autoCompleteList.map(el => el.id);
        this.options.forEach(item => !optionsIdList.includes(item.id) && autoCompleteList.push(item));
        this.defaultOptions = autoCompleteList;
        this.isLoadingOptions = false;
    }

    private async getAutoCompleteList(searchString: string = ''): Promise<VueTreeselectOptions[]> {
        const autoCompleteList = await this.formService.getAutoCompleteData(this.formId, this.questionId, searchString);
        return autoCompleteList.map(item => {
            return { id: item.id, label: item?.keyWord || '' };
        });
    }

    public shouldSearchOptions(searchString: string = ''): boolean {
        const numberCharacter = searchString.length || 0;
        return numberCharacter >= this.minCharacter;
    }

    @debounceFn(600)
    public async loadOptions({ action, searchQuery, callback }): Promise<void> {
        if (action === ASYNC_SEARCH && this.shouldSearchOptions(searchQuery)) {
            const options = await this.getAutoCompleteList(searchQuery);
            callback(null, options);
        }
    }

    public handleSetAnswer(data: VueTreeselectOptions): void {
        this.setAnswer({ answer: data.id, text: data.label });
    }

    public normalizer(node: { id: number; label: string }): { id: number; label: string } {
        return { id: node.id, label: this.customLabelMaxLength(node.label) };
    }

    public customLabelMaxLength(label: string): string {
        if (!label) return;
        return label.replace(/(.{145})..+/, '$1...');
    }
}
