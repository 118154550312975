import { render, staticRenderFns } from "./WrapperFormQuestion.vue?vue&type=template&id=07b43533&scoped=true"
import script from "./WrapperFormQuestion.vue?vue&type=script&lang=ts"
export * from "./WrapperFormQuestion.vue?vue&type=script&lang=ts"
import style0 from "./WrapperFormQuestion.vue?vue&type=style&index=0&id=07b43533&prod&scoped=true&lang=css"
import style1 from "./WrapperFormQuestion.vue?vue&type=style&index=1&id=07b43533&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b43533",
  null
  
)

export default component.exports