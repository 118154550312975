
import Skeleton from '@/app/components/Skeleton.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { Skeleton } })
export default class Logotype extends Vue {
    @Prop() public readonly loading: boolean;
    @Prop() public readonly shouldRender: boolean;
    @Prop() public readonly logo: string;
}
