
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class LanguageSelectorDropdown extends Vue {
    public langModalVisible: boolean = false;

    public setLang(lang: string): void {
        this.setLangModalState(false);
        this.$emit('setLang', lang);
    }

    public setLangModalState(modalState: boolean): void {
        this.langModalVisible = modalState;
    }
}
