import { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface InterceptedRequest {
    match: (requestConfig: AxiosRequestConfig) => boolean;
    response: (requestConfig: AxiosRequestConfig) => unknown;
}

export default class ServiceProxy {
    protected readonly client: AxiosInstance;

    constructor(client: AxiosInstance) {
        this.client = client;
    }

    public blockRequests(requests: InterceptedRequest[]) {
        this.client.interceptors.request.use(
            requestConfig => {
                const request = requests.find(r => r.match(requestConfig));
                return request ? request.response(requestConfig) : requestConfig;
            },
            error => Promise.reject(error),
        );
    }

    public stubResponses(requests: InterceptedRequest[]) {
        this.client.interceptors.response.use(
            responseConfig => responseConfig,
            error => {
                const { requestConfig } = error as { requestConfig: AxiosRequestConfig };
                const request = requests.find(r => r.match(requestConfig));
                return request ? request.response(requestConfig) : error;
            },
        );
    }

    public compareRequest(_method: string, _url: RegExp) {
        return ({ method, url }: AxiosRequestConfig) => {
            return method === _method && !!url.match(_url);
        };
    }

    public rejectResponse(requestConfig: AxiosRequestConfig) {
        return Promise.reject({ requestConfig });
    }
}
