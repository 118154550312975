
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue';
import { Component, Mixins } from 'vue-property-decorator';

@Component({ components: { WrapperFormQuestion, BFormTextarea, BFormGroup, BFormInput } })
export default class SimpleTextComponent extends Mixins(QuestionMixin) {
    public value: string = null;
    public typingTimer: NodeJS.Timeout;

    public created(): void {
        window.addEventListener('beforeunload', this.done);
    }

    public mounted() {
        this.value = this.singleAnswer?.text;
    }

    public done(): void {
        if (this.value) {
            this.setAnswer({ text: this.value });
        }
    }
}
