
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { BButton, BButtonGroup } from 'bootstrap-vue';
import { Component, Mixins } from 'vue-property-decorator';

@Component({ components: { WrapperFormQuestion, BButtonGroup, BButton } })
export default class LikeDislikeComponent extends Mixins(QuestionMixin) {
    get answer(): number | null {
        if (this.currentComponent.answer) {
            return this.singleAnswer.answer;
        }
        return null;
    }
}
