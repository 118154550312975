export default class CustomStyle {
    private style: HTMLLinkElement = null;
    public isLoading = true;

    constructor(private readonly document: Document) {
        this.document = document;

        this.style = this.document.createElement('link');
    }

    public mountStyle(color: string): void {
        this.setStyleLoadWatcher();
        this.setCustomStyleUrl(color);
        this.loadStyle();
    }

    private setStyleLoadWatcher(): void {
        this.style.onload = () => {
            this.isLoading = false;
        };
    }

    private setCustomStyleUrl(color: string): void {
        this.style.setAttribute('href', `https://bootstrap.solucx.com.br/?color=${color}`);
        this.style.setAttribute('rel', 'stylesheet');
    }

    private loadStyle(): void {
        this.document.head.appendChild(this.style);
    }
}
