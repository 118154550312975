
import { Component, Prop, Vue } from 'vue-property-decorator';
import Footer from '@/app/Survey/components/Footer.vue';
import DataUrl from '../model/DataUrl';

@Component({ components: { Footer } })
export default class DefaultContainer extends Vue {
    @Prop({ default: true }) public readonly isFormLoading!: boolean;
    @Prop({ default: null }) public readonly urlData!: DataUrl;

    public get isEnvironmentSandbox(): boolean {
        return this.urlData?.environment === 'sandbox';
    }
}
