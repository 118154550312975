import ElementType from '@/app/Survey/model/conditionals/enum/ElementType';

export default abstract class Element {
    protected id: number;

    constructor(id: number) {
        this.id = id;
    }

    abstract get type(): ElementType;
}
