
import { ITheme } from '@/app/Survey/model/Theme';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormComponentsType } from '../model/Form';

@Component
export default class WrapperFormQuestion extends Vue {
    @Prop() public componentContent!: { [key: string]: string };
    @Prop() public componentSettings!: { [key: string]: boolean };
    @Prop({ default: false }) public twoColumns!: boolean;
    @Prop() public theme!: ITheme;
    @Prop() public componentAlignment!: { [key: string]: string };
    @Prop() public componentType: string;
    @Prop({ default: false }) public readonly isGroupedRadioButton!: boolean;
    @Prop({ default: false }) public readonly isRequired!: boolean;
    @Prop({ default: false }) public readonly currentAnswer!: boolean;
    @Prop({ default: false }) public readonly shouldShowOnlyRequiredLabels!: boolean;
    @Prop({ default: false }) public readonly haveAtLeastOneRequiredQuestion!: boolean;
    @Prop({ default: false }) public readonly isMissingRequiredQuestion!: boolean;
    @Prop({ default: false }) public readonly isAllChildrenAnswered!: boolean;
    @Prop({ default: false }) public readonly isParentGroup!: boolean;
    @Prop({ default: false }) public readonly isDynamicLikeDislike!: boolean;
    @Prop({ default: false }) public readonly isSandbox!: boolean;

    get hasTwoColumns() {
        return this.twoColumns && this.componentContent.title;
    }

    get haveDescriptionAndIsNotGroupedRadioButton(): boolean {
        return this.componentSettings.description && !this.isGroupedRadioButton;
    }

    get componentTitleClasses(): string {
        return this.isGroupedRadioButton ? 'text-dark mb-4 font-weight-bold rounded p-1' : 'question mb-2 text-primary rounded';
    }

    get questionColor(): string {
        return this.theme?.question?.color;
    }

    get invalidFeedbackToRequiredFields(): boolean {
        if (this.isParentGroup) {
            return this.isRequired && this.isMissingRequiredQuestion && !this.isAllChildrenAnswered;
        }
        return this.isRequired && this.isMissingRequiredQuestion && !this.currentAnswer;
    }

    public get showRequiredQuestionLabels(): boolean {
        return this.shouldShowOnlyRequiredLabels && this.isRequired;
    }

    public get showOptionalQuestionLabels(): boolean {
        return this.haveAtLeastOneRequiredQuestion && !this.shouldShowOnlyRequiredLabels && !this.isRequired && !this.isGroupedRadioButton;
    }

    public get showStaticClassLabel(): boolean {
        return this.isDynamicLikeDislike && this.isSandbox;
    }

    public styleComponent(key: string = ''): { [key: string]: string } {
        return key.length === 0 ? this.componentAlignment : { ...this.theme[key], ...this.componentAlignment };
    }

    public alignWrapperComponent(): { [key: string]: string } | string {
        const isAnyOfThoseComponents = [
            FormComponentsType.NumericButtonComponent, //
            FormComponentsType.EmojiComponent,
            FormComponentsType.ParentGroupComponent,
            FormComponentsType.NumbersComponent,
            FormComponentsType.SimpleTextComponent,
        ].includes(this.componentType as FormComponentsType);
        return !isAnyOfThoseComponents && !!this.componentType ? this.styleComponent() : '';
    }
}
